import { useEffect, useState } from 'react';
import type { TouchEvent } from 'react';
import { Tile } from './Tile';
import type { TileT } from './types';

let lastRatioUpdateTime = 0;

const CommunityBoard = ({
    tiles,
    setAndSendCommunityTiles,
    removeFromPlayerTiles
  } : {
    tiles: TileT[];
    setAndSendCommunityTiles: (tiles: TileT[]) => void;
    removeFromPlayerTiles: (tile: TileT) => void;
  }) => {
  const updateRatio = () => {
    // This check keeps it from getting in an infinite loop if the update
    // changes the number of rows the player's tiles wrap on to.
    const now = Date.now();
    if (lastRatioUpdateTime + 100 > now) {
      console.log("updateRatio: Last update was less than 100ms ago. Ignoring.");
      return;
    } else {
      console.log("Updating community board aspect ratio.");
    }
    lastRatioUpdateTime = now;

    const board = document.getElementById("CommunityBoard") as HTMLDivElement;
    const wrapper = board.parentNode as HTMLDivElement;
    const parentAspectRatio = wrapper.clientWidth / wrapper.clientHeight;
    const tiles = document.querySelectorAll(".Tile") as NodeListOf<HTMLDivElement>;
        
    console.log("parentAspectRatio", parentAspectRatio, parentAspectRatio < 16/9 ? '< 16/9' : '> 16/9');
    if (parentAspectRatio < 16/9) {
      // We'll have some empty space under the board.
      board.style.height = `${wrapper.clientWidth * 9/16}px`;
    } else {
      // We'll have some empty space on the sides of the board.
      board.style.height = '100%';
    }
    // The tiles are sized relative to the board.
    for (const sheet of document.styleSheets) {
      for (const rule of sheet.cssRules) {
        if (rule instanceof CSSStyleRule && rule.selectorText === ".Tile") {
          rule.style.width = `${board.clientWidth * 0.045}px`;
          rule.style.height = `${board.clientWidth * 0.055}px`;
          rule.style.fontSize = `${board.clientWidth * 0.028}px`;
        }
      }
    }
  };

  useEffect(() => {
    // Maintaining aspect ratio with CSS is too hard.
    const resizeObserver = new ResizeObserver(() => window.requestAnimationFrame(updateRatio));
    resizeObserver.observe(document.getElementById("CommunityBoard-wrapper") as HTMLDivElement);
    window.requestAnimationFrame(updateRatio);
  }, []);

  return (
    <div id="CommunityBoard-wrapper">
      <div id="CommunityBoard"
        onDragEnter={(e) => {
          // This is required for the mobile-drag-drop polyfill. Without this,
          // the drop handler won't fire on mobile.
          console.log("CB onDragEnter");
          e.preventDefault();
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          console.log("dropped");
          console.log(e);
          const tile = JSON.parse(e.dataTransfer.getData("tile"));
          console.log(tile);
          const board = document.getElementById('CommunityBoard-board')!;
          const rect = board.getBoundingClientRect();
          console.log(rect);
          console.log(tiles);
          console.log("board.clientWidth", board.clientWidth);
          console.log("board.clientHeight", board.clientHeight);
          setAndSendCommunityTiles([{
            tag: tile.tag,
            id: tile.id,
            colour: tile.colour,
            number: tile.number,
            x: (e.clientX - (rect?.left || 0) - tile.offsetX) / board.clientWidth,
            y: (e.clientY - (rect?.top || 0) - tile.offsetY) / board.clientHeight,
          }, ...tiles.filter((t) => t.id !== tile.id)]);
          removeFromPlayerTiles(tile);
        }}
      >
        <h2>Community Board</h2>
        <div id="CommunityBoard-board"
        >
          {tiles.map((tile) => (
            <Tile
              key={tile.id}
              tile={tile}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommunityBoard;

