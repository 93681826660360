import { useState } from 'react';

const getUsernameFromCookie = (): string | undefined => {
    return document.cookie
        .split("; ")
        .find((row) => row.startsWith('rummyking_username='))
        ?.split('=')[1];
}

const setUsernameCookie = (user: string) =>
    document.cookie = `rummyking_username=${user}`;

const JoinGame = ({
  join
}: {
  join: (username: string) => void;
}) => {
  const [username, setUsername] = useState<string | undefined>(getUsernameFromCookie());

  return (
    <form id="JoinGame">
      <div id="username-field" className="mb-3">
        <label htmlFor="username" className="form-label">Your name:</label>
        <input
          type="text"
          id="username"
          name="username"
          className="form-control"
          value={username}
          onChange={e => setUsername(e.target.value)}
          />
      </div>
      <button
        onClick={(e) => {
          const name = !username || username === "" ? `Player ${Math.floor(Math.random() * 10)}` : username;

          setUsernameCookie(name);
          join(name);
          e.preventDefault();
        }}
        className="btn btn-secondary">
        Join the game
      </button>
    </form>
  );
};

export default JoinGame;
