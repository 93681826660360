import { useEffect, useState } from 'react';
import { polyfill } from "mobile-drag-drop";
import './App.css';
import type { TileT, ServerMessage, ClientMessage, ChatMessage } from './types';
import CommunityBoard from './CommunityBoard';
import Chat from './Chat';
import PlayerTiles from './PlayerTiles';
import { connect, send } from './server';
import JoinGame from './JoinGame';

// todo it should tell you when another player draws a tile
// todo show where the other player is dragging a tile (before dropped) also show who is dragging. i started this on a WIP branch
// todo sometimes a player doesn't see the tiles recently placed on the board until they refresh. warn about offline? regular updates to ensure correct state?
// todo audio and video chat
// todo player list
// todo nana's laptop, chrome, new tiles are not highlighted. wasn't because of the css. i don't think they were getting the classname
//      i put in a possible fix for this. haven't asked her to test it yet
// todo turn indicator. will need "finish turn" button
//      put it next to the draw a tile button
//      it can send a chat message "has finished their turn" or something, which the app can use to update the turn indicator
//      make it disabled when it's not your turn

function App() {
  const [joined, setJoined] = useState<boolean>(false);

  const [communityTiles, setCommunityTiles] = useState<TileT[]>([]);

  const [playerTiles, setPlayerTiles] = useState<TileT[]>([]);

  // Used to highlight the new tile after drawing one.
  const [oldPlayerTileIDs, setOldPlayerTileIDs] = useState<string[]>([]);

  const [socket, setSocket] = useState<WebSocket | undefined>(undefined);

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

  useEffect(() => {
    window.addEventListener( 'touchmove', function() {}, {passive: false});
    polyfill();
    // TODO: On mobile, the tile isn't moved exactly where you drop it. Not sure if this helps.
    // polyfill({
    //   dragImageCenterOnTouch: true
    // });
    setSocket((s: WebSocket | undefined) => {
      return s ? s : connect((msg: ServerMessage) => {
        switch (msg.message_type) {
          case "player_tiles":
            setPlayerTiles(msg.tiles);
            break;
          case "community_board":
            setCommunityTiles(msg.tiles);
            break;
          case "chat_messages":
            setChatMessages(msg.chat_messages);
            // Mild hack to avoid highlighting all (most) of your tiles when someone else starts a new game.
            if (msg.chat_messages[0].author === "System" && msg.chat_messages[0].text === "New game started.") {
              setOldPlayerTileIDs([]);
            }
            break;
        }
      });
    });
  }, []);

  const joinGame = (username: string) => {
    send(socket, {
      message_type: "join_game",
      chat_message_text: "",
      tiles: [],
      username
    });
    setJoined(true);
  };

  const sendChatMessage = (message: string) => {
    const msg: ClientMessage = {
      message_type: "chat_message",
      chat_message_text: message,
      tiles: [],
      username: ""
    };
    send(socket, msg);
  };

  const sendCommunityTiles = (tiles: TileT[]) => {
    const msg: ClientMessage = {
      message_type: "community_board",
      chat_message_text: "",
      tiles,
      username: ""
    };
    send(socket, msg);
  };

  // Also sends the new tiles to the server.
  const setAndSendCommunityTiles = (tiles: TileT[]) => {
    sendCommunityTiles(tiles);
    setCommunityTiles(tiles);
  };

  useEffect(() => {
    const msg: ClientMessage = {
      message_type: "player_tiles",
      chat_message_text: "",
      tiles: playerTiles,
      username: ""
    };
    send(socket, msg);
  }, [socket, playerTiles]);

  const newGame = () => {
    const confirm = window.confirm("Start a new game?");
    if (confirm) {
      send(socket, {
        message_type: "new_game",
        chat_message_text: "",
        tiles: [],
        username: ""
      });
      setOldPlayerTileIDs([]);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={newGame} className="new-game btn btn-secondary">New game</button>
        <h1>Rummyking</h1>
      </header>
      <div id="CommunityBoardAndChat">
        <CommunityBoard
          tiles={communityTiles}
          setAndSendCommunityTiles={setAndSendCommunityTiles}
          removeFromPlayerTiles={(tile) => setPlayerTiles(playerTiles.filter((t) => t.id !== tile.id))}
        />
        <Chat messages={chatMessages} sendMessage={sendChatMessage} />
      </div>
      {joined ?
        <PlayerTiles
          tiles={playerTiles}
          oldTileIDs={oldPlayerTileIDs}
          drawATile={() => {
            setOldPlayerTileIDs(playerTiles.map(t => t.id));
            send(socket, {
              message_type: "draw_a_tile",
              chat_message_text: "",
              tiles: [],
              username: ""
            });
          }}
          endYourTurn={() => {
            send(socket, {
              message_type: "end_turn",
              chat_message_text: "",
              tiles: [],
              username: ""
            });
          }}
          setPlayerTiles={setPlayerTiles}
          removeFromCommunityTiles={(tile) => setAndSendCommunityTiles(communityTiles.filter((t) => t.id !== tile.id))}
          /> :
        <JoinGame join={joinGame} />}
    </div>
  );
}

export default App;
