import { ClientMessage, ServerMessage } from "./types";

const getUser = (): string => {
    const user = document.cookie
        .split("; ")
        .find((row) => row.startsWith('rummyking_user='))
        ?.split('=')[1];

    if (user) {
        return user;
    } else {
        setUser((Math.random() + 1).toString(36).substring(5));
        return getUser();
    }
};

const setUser = (user: string) =>
    document.cookie = `rummyking_user=${user}`;

export const connect = (onMessage: (msg: ServerMessage) => void) => {
    console.log("Connecting");
    const socket = new WebSocket(
        window.location.hostname == 'rummy.neideck.com' ?
            // The trailing slash is important.
            'wss://rummy.neideck.com/websocket/' :
            'ws://192.168.0.167:9160');

    socket.addEventListener('open', (event) => {
        console.log("Socket opened");
        const msg = `Hi! I am ${getUser()}`;
        console.log("Sending: ", msg);
        socket.send(msg);
    });

    socket.addEventListener('message', (event) => {
        console.log('Message from server ', event.data);
        try {
            const msg = JSON.parse(event.data);
            onMessage(msg);
        } catch { }
    });

    return socket;
};

export const send = (socket: WebSocket | undefined, message: ClientMessage) => {
    const rs = socket?.readyState;
    if (rs === 1) {
      console.log("Sending", message);
      const msgJson = JSON.stringify(message);
      socket?.send(msgJson);
    } else {
      console.log("Socket not ready. Can't send. readyState:", rs, "message:", message);
    }
};