import { useEffect } from "react";
import { ChatMessage } from "./types";

const Chat = ({
    messages,
    sendMessage
}: {
    messages: ChatMessage[],
    sendMessage: (message: string) => void
}) => {
    const send = () => {
        const text = document.getElementById("Chat-input-text") as HTMLInputElement;
        sendMessage(text.value);
        text.value = "";
    };

    // Scroll to the bottom of the chat messages on new message.
    useEffect(() => {
        const chat = document.getElementById("Chat-messages");
        if (chat) {
            chat.scrollTop = chat.scrollHeight;
        }
    }, [messages]);

    return (
        <div id="Chat-wrapper">
            <div id="Chat">
                <h2>Chat</h2>
                <div id="Chat-messages">
                    {[...messages].reverse().map((message, i) => (
                        <div className="Chat-message" key={i}>
                            {message.author === "System" ?
                                <div className="Chat-message-event system-event">{message.text}</div> :
                                message.text === "<Joined>" ?
                                    <div className="Chat-message-event">{message.author} joined the game.</div> :
                                message.text === "<Rejoined>" ?
                                    <div className="Chat-message-event">{message.author} reconnected.</div> :
                                message.text === "<Disconnected>" ?
                                    <div className="Chat-message-event">{message.author} disconnected.</div> :
                                message.text === "<Ended their turn>" ?
                                    <div className="Chat-message-event">{message.author} ended their turn.</div> :
                                message.text === "<Drew a tile>" ?
                                    <div className="Chat-message-event">{message.author} drew a tile.</div> : (
                                        <>
                                            <div className="Chat-message-author">{message.author}</div>
                                            <div className="Chat-message-text">{message.text}</div>
                                        </>
                                    )}
                        </div>
                    ))}
                </div>
                <div id="Chat-input">
                    <input type="text" id="Chat-input-text" className="form-control" onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            send();
                            ev.preventDefault();
                        }
                    }} />
                    <button id="Chat-input-send" className="btn btn-secondary" onClick={send}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default Chat;