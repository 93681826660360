import type { TouchEvent } from 'react';
import type { TileT } from './types';

export const Tile = ({
    tile,
    isNew = false
  } : {
    tile: TileT;
    isNew?: boolean;
  }) => {
  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    console.log("Tile onTouchStart");
    e.preventDefault();
    e.stopPropagation();
        console.log(e);
        // console.log("offsetX", e.nativeEvent.offsetX);
        // console.log("offsetY", e.nativeEvent.offsetY);

        // Add the id of the drag source element to the drag data payload so
        // it is available when the drop event is fired
        // e.dataTransfer.setData("tile", JSON.stringify({
        //   ...tile,
        //   offsetX: e.nativeEvent.offsetX,
        //   offsetY: e.nativeEvent.offsetY,
        // }));
        // e.dataTransfer.effectAllowed = "move";
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    console.log("onTouchMove");
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={`Tile ${tile.tag === "RegularTile" ? tile.colour : "smiley"} ${isNew ? "new" : ""}`}
      draggable={true}
      onDragEnter={(e) => {
        // This is required for the mobile-drag-drop polyfill. Without this, the
        // drop handler won't fire on mobile.
        console.log("Tile onDragEnter");
        e.preventDefault();
      }}
      onDragStart={(e) => {
        console.log("dragStart");
        console.log(e);
        console.log("offsetX", e.nativeEvent.offsetX);
        console.log("offsetY", e.nativeEvent.offsetY);

        // Add the id of the drag source element to the drag data payload so
        // it is available when the drop event is fired
        e.dataTransfer.setData("tile", JSON.stringify({
          ...tile,
          offsetX: e.nativeEvent.offsetX,
          offsetY: e.nativeEvent.offsetY,
        }));
        e.dataTransfer.effectAllowed = "move";
      }}
      style={tile.x && tile.y ? {
        position: "absolute",
        margin: 0,
        top: `${tile.y * 100}%`,
        left: `${tile.x * 100}%`,
      } : {}}
    >
      {tile.tag === "RegularTile" ? tile.number : "☺"}
    </div>
  );
};
