import { useState, useEffect } from 'react';
import { Tile } from './Tile';
import type { TileT } from './types';

const PlayerTiles = ({
  tiles,
  oldTileIDs,
  drawATile,
  endYourTurn,
  setPlayerTiles,
  removeFromCommunityTiles
}: {
  tiles: TileT[];
  oldTileIDs: string[];
  drawATile: () => void;
  endYourTurn: () => void;
  setPlayerTiles: (tiles: TileT[]) => void;
  removeFromCommunityTiles: (tile: TileT) => void;
}) => {
  // This is used as a backup strat to try to fix the bug where the new tile is not highlighted, which I can't reproduce.
  const [oldTiles, setOldTiles] = useState<TileT[]>([]);
  // Don't highlight all tiles after starting a new game.
  useEffect(() => {
    if (oldTileIDs.length === 0) {
      setOldTiles([]);
    }
  }, [oldTileIDs]);

  return (
    <div id="PlayerTiles"
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        const tile = JSON.parse(e.dataTransfer.getData("tile"));
        console.log(`Dropped into player tiles: ${tile}`);
        removeFromCommunityTiles(tile);
        setPlayerTiles([{
          tag: tile.tag,
          id: tile.id,
          colour: tile.colour,
          number: tile.number,
          x: 0,
          y: 0,
        }, ...tiles.filter((t) => t.id !== tile.id)]);
      }}
    >
      <h2>Your Tiles</h2>
      <div id="PlayerTiles-buttons">
        <button onClick={() => { setOldTiles(tiles); drawATile(); }} className="btn btn-secondary">Draw a tile</button>
        <button onClick={endYourTurn} className="btn btn-secondary">End your turn</button>
      </div>
      <div className="PlayerTiles-rack">
        {tiles
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((tile) =>
            <Tile
              tile={tile}
              key={tile.id}
              isNew={(oldTileIDs.length !== 0 && !oldTileIDs.includes(tile.id)) ||
                // Backup strat
                (oldTiles.length !== 0 && !oldTiles.find((t: TileT) => t.id === tile.id))}
            />)}
      </div>
    </div>
  );
};

export default PlayerTiles;
